.participation {
  padding-top: 120px;
  padding-right: 15%;
  padding-left: 10%;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.participation h4 {
  margin-top: 50px;
  padding: 5px;
  background-color: #f7f11e;
}

#transparent-bg {
  background-color: rgba(0, 0, 0, 0) !important;
}

.participation h5 {
  padding-top: 50px;
}

.participation p {
  padding-top: 40px;
  padding-bottom: 40px;
}

.participation-list {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
