.archive {
  max-width: 1200px;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 80px;
}

ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul {
  margin-top: 10px;
  padding: 0;
}

p {
  padding-top: 10px;
  padding-bottom: 10px;
}

.archive-title {
  margin-bottom: 15px;
}
