.organizers-container {
  max-width: 1200px;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 80px;
}

.organizers-container p {
  margin-bottom: 20px;
  margin-top: 20px;
}

.organizers-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.organizers-image img {
  width: 400px;
  height: 450px;
}

@media screen and (max-width: 500px) {
  .organizers-image img {
    width: 250px;
    height: 250px;
  }

  .organizers-container {
    max-width: 400px;
    margin: auto;
    padding-top: 70px;
    padding-bottom: 40px;
    text-align: center;
  }

  .organizers-container p {
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 18px;
  }
}
