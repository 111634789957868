.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #113156;
  color: whitesmoke;
}

.footer h6,
.footer-contacts {
  padding-left: 40px;
}

.footer p {
  font-size: 1.1rem;
}

.footer h6 {
  font-size: 1.5rem;
}

.footer-contacts {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.footer-contacts p {
  padding-top: 5px;
}

@media only screen and (max-width: 800px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer h6,
  .footer-contacts,
  .footer-sponsors {
    padding-left: 0px;
  }

  .footer-sponsors {
    padding-top: 50px;
  }

  .footer {
    align-items: center;
  }

  .footer-contacts {
    align-items: center;
  }

  .footer p {
    font-size: 1rem;
  }

  .footer h6 {
    font-size: 1.3rem;
  }
}
