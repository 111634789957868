@font-face {
  font-family: 'Montserrat';
  src: url('./Fonts/Montserrat-Regular.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:is(h1, h2, h3, h4, h5, h6, p) {
  margin: 0;
}

h1 {
  font-size: 4.5rem;
  font-weight: 500;
}

h2 {
  font-size: 4rem;
  font-weight: 400;
}

h3 {
  font-size: 3rem;
  font-weight: 300;
}

h4 {
  font-size: 2.5rem;
  font-weight: 500;
  color: #113156 !important;
}

h5 {
  font-size: 2rem;
  font-weight: 400;
  color: #113156 !important;
}

h6 {
  font-size: 1.7rem;
  font-weight: 300;
}

p {
  font-size: 1.4rem;
}

button {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
}

a {
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  h1 {
    font-size: 4rem;
    font-weight: 500;
  }

  h2 {
    font-size: 3rem;
    font-weight: 400;
  }

  h3 {
    font-size: 2.3rem;
    font-weight: 300;
  }

  h4 {
    font-size: 2rem;
    font-weight: 500;
    color: #113156 !important;
  }

  h5 {
    font-size: 1.5rem;
    font-weight: 400;
    color: #113156 !important;
  }

  h6 {
    font-size: 1.3rem;
    font-weight: 300;
  }

  p {
    font-size: 1.3rem;
  }

  a {
    font-size: 1rem !important;
  }
}
