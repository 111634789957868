.landing-container {
  width: 100%;
  height: 100vh;
  background-image: url("../Images/landing_img.jpeg");
  background-size: cover;
}

.landing-body {
  width: 35%;
  height: 100vh;
  color: whitesmoke;
  text-align: left;
  background-color: #113156;
  padding-left: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing-body h2 {
  width: 80%;
}

.landing-body h6 {
  padding-top: 20px;
  width: 80%;
}

.landing-body-text {
  padding-top: 50px;
  padding-bottom: 80px;
}

.landing-body > button {
  width: 230px;
  height: 80px;
  border: none;
  outline: none;
  border-radius: 13px;
  background-color: #f9bf1c;
}

@media only screen and (max-width: 800px) {
  .landing-body {
    width: auto;
    text-align: center;
    align-items: center;
    padding-left: 0px;
  }
  .landing-body-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
