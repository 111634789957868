.navbar {
  position: fixed;
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #113156;
  color: white;
  box-shadow: 1px 0 5px black;
  z-index: 2;
}

#navbar-home-btn {
  font-size: 1.3rem;
  text-decoration: none;
  color: whitesmoke;
}

.navbar-link {
  padding: 25px;
  text-decoration: none;
  color: whitesmoke;
}

.minimenu {
  display: none;
  cursor: pointer;
}

.minimenu div {
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: whitesmoke;
}

@media only screen and (max-width: 1380px) {
  .links {
    position: absolute;
    right: 0;
    top: 8vh;
    height: 80vh;
    width: 80%;
    background-color: #1b4a80;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    font-size: 20px;
  }

  .minimenu {
    display: block;
  }

  .links.active {
    transform: translateX(0%);
    transition: 500ms;
  }
}
