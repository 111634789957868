img {
  width: 1000px;
  height: 500px;
}

@media (max-width: 1000px) {
  img {
    width: 700px;
    height: 400px;
  }
}

@media (max-width: 700px) {
  img {
    width: 500px;
    height: 300px;
  }
}

@media (max-width: 400px) {
  img {
    width: 300px;
    height: 200px;
  }
}
